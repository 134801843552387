import { inject, Injectable } from '@angular/core';
import { GenericLogger } from '@scalefast/ecommerce-core';
import { ContentfulFAQLandingPageContent } from 'src/app/core/interfaces/Contentful/Page/ContentfulFAQLandingPageContent';
import { ContentfulPageFields } from 'src/app/core/interfaces/Contentful/Page/ContentfulPageFields';
import { ContentfulPageContent } from 'src/app/core/interfaces/Contentful/Page/ContentfulPageContent';
import { ContentfulFaqService } from './contentful-faq.service';
import { ContentfulLayoutZoneBlockContent } from 'src/app/core/interfaces/Contentful/LayoutZone/ContentfulLayoutZoneContent';
import { ContentfulLayoutZoneSkeleton } from 'src/app/core/interfaces/Contentful/LayoutZone/ContentfulLayoutZoneSkeleton';
import { ContentfulLayoutZoneBlock } from 'src/app/core/interfaces/Contentful/LayoutZone/ContentfulLayoutZoneBlock';
import { ContentfulCloudinaryImage } from 'src/app/core/interfaces/Contentful/CloudinaryImage/ContentfulCloudinaryImage';
import { LayoutZoneContentException } from 'src/app/core/Exceptions/Contentful/LayoutZoneContentException';
import { ContentfulImageRendition } from 'src/app/core/interfaces/Contentful/ImageRendition/ContentfulImageRenditionSkeleton';
import { getAssetSysFake, isAContentfulResolvedAsset } from '../helpers/contentful.helper';
import { ContentfulBaseService } from './contentful-base.service';
import { ContentfulArticleContent } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticleContent';
import { ContentfulArticleSkeleton } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticleSkeleton';
import { ContentfulArticle } from 'src/app/core/interfaces/Contentful/Article/ContentfulArticle';
import { ContentfulArticleOverviewContent } from 'src/app/core/interfaces/Contentful/ArticleOverview/ContentfulArticleOverviewContent';
import { ContentfulClientService } from './contentful-client.service';
import {
  ContentfulCloudinaryVideo,
  ContentfulCloudinaryVideoSkeleton,
} from 'src/app/core/interfaces/Contentful/CloudinaryVideo/ContentfulCloudinaryVideoSkeleton';
import { ContentfulPagePathContent } from 'src/app/core/interfaces/Contentful/PagePath/ContentfulPagePathContent';
import { ContentfulPagePathSkeleton } from 'src/app/core/interfaces/Contentful/PagePath/ContentfulPagePathSkeleton';

@Injectable({
  providedIn: 'root',
})
export class ContentfulPageService {
  #logger = inject(GenericLogger);
  #contentfulClientService = inject(ContentfulClientService);
  #contentfulBaseService = inject(ContentfulBaseService);
  #contentfulFaqService = inject(ContentfulFaqService);

  async getPageContent(item: any): Promise<ContentfulPageContent | ContentfulFAQLandingPageContent> {
    let contentfulPageContent: ContentfulPageContent | ContentfulFAQLandingPageContent = { title: 'empty-content' };
    const contentfulPageItems: ContentfulPageFields | any = item.fields;

    if (contentfulPageItems.code === 'faq') {
      //TODO: Check this promise it's probably not necessary
      return this.#contentfulFaqService.getFAQLandingContent(
        contentfulPageItems.blocks[0].fields.blocks[0].sys.id,
        contentfulPageItems.metadata,
      );
    }

    const contentfulPageMetadataFields = contentfulPageItems.metadata.fields;
    const openGraphImageId = contentfulPageMetadataFields.openGraphImage?.sys.id;
    const layoutZoneId = contentfulPageItems.blocks[0].fields.blocks[0].sys.id;

    const contentfulCloudinaryImage = contentfulPageMetadataFields.openGraphImage.fields;

    const openGraphImageUrl = contentfulCloudinaryImage?.asset?.fields.file?.url;
    if (openGraphImageUrl !== 'empty-content') {
      contentfulPageContent = {
        title: contentfulPageItems.name,
        meta: contentfulPageItems.metadata,
        description: contentfulPageMetadataFields.metaDescription,
        openGraphImageId,
        openGraphImageUrl,
        layoutZoneId,
      };
    }
    this.#logger.debug('ContentfulPageService.getPageContent', contentfulPageContent);
    return contentfulPageContent;
  }

  async getLayoutZoneContent(rootContent: ContentfulPageContent): Promise<Array<ContentfulLayoutZoneBlockContent>> {
    let layoutZoneBlockContent: Array<ContentfulLayoutZoneBlockContent> = [];
    const contentId = rootContent.layoutZoneId;
    try {
      const contentfulQuery = {
        include: 3, // Minimum functional value
        content_type: 'zone',
        'sys.id': contentId,
        select: 'fields.blocks',
      };

      const contentfulClient = await this.#contentfulClientService.getClient();
      const layoutZoneEntries = await contentfulClient.getEntries<ContentfulLayoutZoneSkeleton>(contentfulQuery);
      const zoneLayoutBlocksContent: Array<ContentfulLayoutZoneBlock> = layoutZoneEntries.items[0]?.fields?.blocks || [];
      const blockPromise: Array<Promise<ContentfulLayoutZoneBlockContent>> = zoneLayoutBlocksContent.map((block) => {
        const deviceConfigurations = block.fields.devicesConfigurations;
        const heroVideo = (block as any)?.fields?.video;
        const heroImage: ContentfulCloudinaryImage = heroVideo?.fields?.keyframe?.fields;
        let categoryHeading = '';
        if (block.fields.text?.content?.[0]) {
          categoryHeading = block.fields.text.content[0]?.content?.[0]?.value;
        } else if (block.fields.title) {
          categoryHeading = block.fields.title;
        }
        const contentType = block.sys.contentType.sys.id;
        if (deviceConfigurations?.length) {
          return this.#getContentBlockConfigurationWithImages(
            deviceConfigurations,
            categoryHeading,
            block,
            heroImage,
            contentType,
            rootContent,
          );
        } else {
          return this.#getContentBlockConfigurationWithArticlesList(block, categoryHeading, contentType, rootContent);
        }
      });

      layoutZoneBlockContent = await Promise.all(blockPromise);
      return layoutZoneBlockContent;
    } catch (error) {
      if (error instanceof Error) {
        throw new LayoutZoneContentException(error.message, { cause: error });
      }

      throw error;
    } finally {
      this.#logger.debug('ContentfulPageService.getLayoutZoneContent', layoutZoneBlockContent);
    }
  }

  async getContentTypeFromPagePath(pagePathContent: ContentfulPagePathContent, completeSlug: string): Promise<any[]> {
    const contentfulClient = await this.#contentfulClientService.getClient();
    const content = await contentfulClient.getEntries({
      include: 2, // Minimum functional value
      links_to_entry: pagePathContent.id,
      locale: pagePathContent.locale,
    });

    return content.items
      .filter((item) => item.sys.contentType.sys.id !== 'pageLink')
      .map((item) => {
        const auxItem = {
          ...item,
          entryId: item.sys.id,
          slug: completeSlug,
          contentId: item.sys.id,
          contentType: item.sys.contentType.sys.id,
        };
        return auxItem;
      });
  }

  async #getContentBlockConfigurationWithImages(
    deviceConfigurations: any,
    categoryHeading: string,
    block: any,
    heroImage: ContentfulCloudinaryImage,
    contentType: string | undefined,
    rootContent: ContentfulPageContent,
  ): Promise<ContentfulLayoutZoneBlockContent> {
    const backgroundImageAssetId = deviceConfigurations[0]?.sys?.id || '';
    const backgroundImageMobileAssetId = deviceConfigurations[1]?.sys?.id || '';
    let backgroundImageMobileAssetUrl: ContentfulCloudinaryImage | undefined = undefined;
    let disclaimer: string | undefined;

    const backgroundAssetImage = block.fields.devicesConfigurations[1]?.fields?.backgroundAsset?.fields;
    const backgroundImageAsset = block.fields.devicesConfigurations[0]?.fields?.backgroundAsset?.fields;

    let contentfulEmbeddedBlockData = this.#contentfulBaseService.getEmbeddedBlockFromEntry(block);

    const disclaimerBlock = block.fields.disclaimerReference
      ? this.#contentfulBaseService.getEmbeddedBlockFromEntry(block.fields.disclaimerReference, 'disclaimer')
      : undefined;

    if (backgroundAssetImage) {
      backgroundImageMobileAssetUrl = backgroundAssetImage;
    }

    if (disclaimerBlock) {
      disclaimer = disclaimerBlock.content;
    }
    /**
     * @TODO: Still pending to refactor the HTML of the contentfulCloudinaryImage component in order to use the width, height and
     * other image properties from the contentfulImageRendition object contained inside the contentfulCloudinaryImage object.
     */
    const imageRendition: ContentfulImageRendition = {
      name: 'image-rendition',
      aspectRatio: 0,
      transformations: '',
      width: 800,
      height: 800,
    };
    // Fake asset sys object needed to create a contentfulCloudinaryImage object
    const assetSysFake = getAssetSysFake();
    // We need to create a contentfulCloudinaryImage object to give it to the contentfulCloudinaryImage component
    // FIXME: The creation of this object by assigning empty data indicates that this is done to avoid errors. This is a patch that we should avoid. Analyze it and correct it
    const cloudinaryImage: ContentfulCloudinaryImage = {
      alternateText: contentfulEmbeddedBlockData.content || '',
      imageRendition,
      isPreload: false,
      name: contentfulEmbeddedBlockData.content || '',
      asset: {
        sys: assetSysFake.sys,
        metadata: assetSysFake.metadata,
        fields: {
          file: {
            contentType: contentfulEmbeddedBlockData.mediaType || '',
            fileName: contentfulEmbeddedBlockData.content || '',
            url: contentfulEmbeddedBlockData.url || '',
            details: assetSysFake.fields.file!.details, // File always exists in assetSysFake. Non-null assertion is safe
          },
          title: contentfulEmbeddedBlockData.content || '',
        },
      },
    };

    const heroImageUrl = heroImage?.asset?.fields.file?.url;
    const backgroundImageAssetUrl = backgroundImageAsset?.asset?.fields.file?.url;
    const contentfulCloudinaryImage = backgroundImageAssetUrl ? backgroundImageAsset : cloudinaryImage;
    const contentfulCloudinaryImageUrl = backgroundImageAssetUrl
      ? backgroundImageAsset.asset?.fields.file?.url
      : cloudinaryImage.asset?.fields.file?.url;

    return {
      name: block.fields.name,
      shortName: block.fields.shortName,
      meta: rootContent?.meta,
      title: block.fields.title,
      ctaLabel: block.fields.ctaLabel,
      description: block.fields.description,
      categoryHeading,
      callToActionLabel: block.fields.callToActionLabel,
      callToActionLink: block.fields.callToActionLink,
      backgroundImageAssetId,
      backgroundImageAsset: contentfulCloudinaryImage,
      backgroundImageAssetUrl: contentfulCloudinaryImageUrl,
      backgroundImageMobileAssetId,
      backgroundImageMobileAssetUrl,
      heroImage,
      heroImageUrl,
      titleLevel: block.fields.titleLevel,
      contentType: contentType,
      disclaimer,
      surtitle: block.fields.surtitle,
    };
  }

  async #getContentBlockConfigurationWithArticlesList(
    block: ContentfulLayoutZoneBlock,
    categoryHeading: string,
    contentType: string | undefined,
    rootContent: ContentfulPageContent | undefined,
  ): Promise<ContentfulLayoutZoneBlockContent> {
    const blockContentType = block.sys.contentType.sys.id;
    const blockArticlesList = block.fields.articleList;

    if (blockContentType === 'articleListBlock' && blockArticlesList) {
      const articleIds: Array<string> = blockArticlesList.map(
        (blockArticleList: { sys: { id: string } }) => blockArticleList.sys.id,
      );

      const articleOverviewContents = await Promise.all(
        articleIds.map((articleId: string) => this.#getArticleContentById(articleId)),
      );

      return {
        name: block.fields.name,
        meta: rootContent?.meta || undefined,
        shortName: block.fields.shortName,
        title: block.fields.title,
        ctaLabel: block.fields.ctaLabel,
        description: block.fields.description,
        categoryHeading,
        callToActionLabel: block.fields.callToActionLabel,
        callToActionLink: block.fields.callToActionLink,
        articleIds,
        articleList: articleOverviewContents,
        contentType: contentType,
        surtitle: block.fields.surtitle,
      };
    } else if (blockContentType === 'contentDetailsBlock' && block.fields.details && block.fields.details.length > 0) {
      const contentfulClient = await this.#contentfulClientService.getClient();
      const detailBlockPromises = block.fields.details.map((entry) => {
        const entryId = entry.sys.id;

        // FIXME: This (contentfulEntry: any) is needed to avoid typescript error in the getEntry method. In this case, any is considered because do not exist a type for this entry
        return contentfulClient.getEntry<any>(entryId).then((contentfulEntry: any) => ({
          name: contentfulEntry.fields.name,
          title: contentfulEntry.fields.title,
          description: contentfulEntry.fields.description,
          image: {
            title: contentfulEntry.fields.logo.fields.asset.fields.title,
            url: contentfulEntry.fields.logo.fields.asset.fields.file.url,
            contentType: contentfulEntry.fields.logo.fields.asset.fields.file.contentType,
          },
        }));
      });

      const resolvedBlocks = await Promise.all(detailBlockPromises);

      return {
        name: block.fields.name,
        shortName: block.fields.shortName,
        title: block.fields.title,
        ctaLabel: block.fields.ctaLabel,
        description: block.fields.description,
        categoryHeading,
        callToActionLabel: block.fields.callToActionLabel,
        callToActionLink: block.fields.callToActionLink,
        contentType: contentType,
        detailBlocks: resolvedBlocks,
        surtitle: block.fields.surtitle,
      };
    } else if (blockContentType === 'htmlBlock') {
      // Check if description contains a '.' char at the end, remove it to avoid bad formatting
      let description = block.fields.text;
      if (description.endsWith('.')) {
        description = description.slice(0, -1);
      }
      return {
        name: block.fields.name,
        description,
        categoryHeading,
        contentType: contentType,
      };
    } else if (blockContentType === 'contentVideo') {
      const cloudinaryVideo = await this.#getCloudinaryVideo(block.fields.video!.sys.id);
      if (!isAContentfulResolvedAsset(cloudinaryVideo?.asset)) {
        throw new Error('Contentful video asset not found'); // FIXME: We should handle this error with notifyError method
      }

      return {
        name: block.fields.name,
        videoUrl: cloudinaryVideo.asset.fields.file?.url,
        categoryHeading,
        contentType: contentType,
      };
    } else if (block.fields.category) {
      const categoryId = block.fields.category.sys.id;
      const contentfulArticlesContent = await this.#getCategoryArticlesContent(categoryId);

      return {
        name: block.fields.name,
        meta: rootContent?.meta || undefined,
        shortName: block.fields.shortName,
        title: block.fields.title,
        ctaLabel: block.fields.ctaLabel,
        description: block.fields.description,
        categoryHeading,
        callToActionLabel: block.fields.callToActionLabel,
        callToActionLink: block.fields.callToActionLink,
        articleList: contentfulArticlesContent,
        contentType: contentType,
        surtitle: block.fields.surtitle,
      };
    } else {
      return {
        name: block.fields.name,
        shortName: block.fields.shortName,
        title: block.fields.title,
        ctaLabel: block.fields.ctaLabel,
        description: block.fields.description,
        categoryHeading,
        callToActionLabel: block.fields.callToActionLabel,
        callToActionLink: block.fields.callToActionLink,
        articleList: [],
        contentType: contentType,
        surtitle: block.fields.surtitle,
      };
    }
  }

  async #getArticleContentById(contentId: string): Promise<ContentfulArticleContent> {
    let contentfulArticleContent: ContentfulArticleContent = {};

    const contentfulQuery = {
      include: 3,
      content_type: 'article',
      'sys.id': contentId,
      select: 'fields.backgroundAsset, fields.articleOverview, fields.contents',
    };

    const contentfulClient = await this.#contentfulClientService.getClient();
    const contentfulArticleEntries = await contentfulClient.getEntries<ContentfulArticleSkeleton>(contentfulQuery);

    if (contentfulArticleEntries.total <= 0) {
      this.#contentfulBaseService.notifyContentfulEmptyResults(contentfulQuery);
      return contentfulArticleContent;
    }

    const contentfulArticle: ContentfulArticle = contentfulArticleEntries.items[0].fields;
    const backgroundAsset: ContentfulCloudinaryImage = contentfulArticle.backgroundAsset?.fields;
    const thumbnailImage: ContentfulCloudinaryImage = contentfulArticle.articleOverview.fields.thumbnailImage.fields;
    const mobileThumbnail: ContentfulCloudinaryImage | undefined =
      contentfulArticle.articleOverview.fields.mobileThumbnail?.fields;
    let thumbnailImageUrl;

    // FIXME: Using non-null assertion in asset. This can fail if contentful is configured incorrectly, but it is not a problem for now
    if (contentfulArticle.backgroundAsset && backgroundAsset.asset!.fields.file?.url) {
      const backgroundAssetUrl = backgroundAsset.asset!.fields.file?.url;
      thumbnailImageUrl = backgroundAssetUrl;
    } else if (thumbnailImage && thumbnailImage.asset!.fields.file?.url) {
      const url = thumbnailImage.asset!.fields.file?.url;
      if (url !== '') {
        thumbnailImageUrl = url;
      }
    } else {
      thumbnailImageUrl = this.#contentfulBaseService.getDefaultImageAssetUrl(
        'No thumbnail image found for article overview content.',
      );
    }

    const articleOverview: ContentfulArticleOverviewContent = {
      name: contentfulArticle.articleOverview.fields.name || '',
      title: contentfulArticle.articleOverview.fields.title,
      description: contentfulArticle.articleOverview.fields.description || '',
      thumbnailImage,
      mobileThumbnail,
      thumbnailImageUrl: thumbnailImageUrl,
      slug: contentfulArticle.articleOverview.fields.slug?.sys.id || '',
    };

    const formattedContents = this.#contentfulBaseService.formatContentfulRichBlockContent(contentfulArticle.contents);
    // @FIXME: Check the case where the slug is undefined
    const pagePathContent = await this.#getPagePathFromSlugId(articleOverview.slug as string);

    articleOverview.slug = pagePathContent.slug;

    contentfulArticleContent = {
      overview: articleOverview,
      contents: formattedContents,
      backgroundAsset,
    };

    return contentfulArticleContent;
  }

  // FIXME: Fix this method to return the correct type
  async #getCloudinaryVideo(contentId: string): Promise<ContentfulCloudinaryVideo | undefined> {
    const contentfulQuery = {
      content_type: 'cloudinaryVideo',
      'sys.id': contentId,
    };

    const contentfulClient = await this.#contentfulClientService.getClient();
    const cloudinaryVideo = await contentfulClient.getEntries<ContentfulCloudinaryVideoSkeleton>(contentfulQuery);

    if (cloudinaryVideo.total === 0) {
      return undefined;
    }

    return cloudinaryVideo.items[0].fields;
  }

  async #getCategoryArticlesContent(contentId: string): Promise<Array<ContentfulArticleContent>> {
    const contentfulArticlesContent: ContentfulArticleContent[] = [];

    const contentfulClient = await this.#contentfulClientService.getClient();
    const content = await contentfulClient.getEntries<ContentfulArticleSkeleton>({
      links_to_entry: contentId,
      select: ['fields'],
    });

    if (content.total === 0) {
      this.#contentfulBaseService.notifyContentfulEmptyResults(contentId);
      return contentfulArticlesContent;
    }

    const contentfulArticles = content.items;
    const slugPromises: Array<Promise<string>> = [];
    const articleOverviews: Array<ContentfulArticleOverviewContent> = [];

    for (const contentfulArticle of contentfulArticles) {
      const contentfulArticleFields: ContentfulArticle = contentfulArticle.fields; // FIXME: Why fields object has properties with never values?
      const articleSlugId = contentfulArticleFields.articleOverview?.fields.slug?.sys.id || '';
      const thumbnailImage: ContentfulCloudinaryImage = contentfulArticleFields.backgroundAsset?.fields;

      const slugPromise = articleSlugId !== '' ? this.#getSlugFromId(articleSlugId) : Promise.resolve('');
      slugPromises.push(slugPromise);

      const contentfulArticleOverviewContent: ContentfulArticleOverviewContent = {
        name: contentfulArticleFields.articleOverview?.fields.name || '',
        title: contentfulArticleFields.articleOverview?.fields.title,
        description: '',
        slug: undefined, // undefined for now
        thumbnailImage,
        thumbnailImageUrl:
          contentfulArticleFields.backgroundAsset?.fields.asset?.fields.file?.url ??
          this.#contentfulBaseService.getDefaultImageAssetUrl('No thumbnail image found for article overview content.'),
      };

      articleOverviews.push(contentfulArticleOverviewContent);
    }

    const slugs = await Promise.all(slugPromises);

    // NOTE: We are assuming that the order of the slugs is the same as the order of the articleOverviews
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all
    // Despite performing two loops, it is more efficient to resolve the promises in parallel and assign the value later.
    for (let i = 0; i < articleOverviews.length; i++) {
      articleOverviews[i].slug = slugs[i];
      contentfulArticlesContent.push({
        overview: articleOverviews[i],
      });
    }

    return contentfulArticlesContent;
  }

  async #getSlugFromId(slugId: string): Promise<string> {
    const contentfulPagePathContent = await this.#getPagePathFromSlugId(slugId);
    return contentfulPagePathContent.slug || '';
  }

  async #getPagePathFromSlugId(slugId: string): Promise<ContentfulPagePathContent> {
    let contentfulPagePathyContent: ContentfulPagePathContent = {
      id: 'empty-content',
      locale: '',
    };

    const contentfulQuery = {
      content_type: 'pagePath',
      'sys.id': slugId,
      select: 'fields.slug',
    };

    const contentfulClient = await this.#contentfulClientService.getClient();
    const contentfulPagePathContentEntries = await contentfulClient.getEntries<ContentfulPagePathSkeleton>(contentfulQuery);
    if (contentfulPagePathContentEntries.total === 0) {
      this.#contentfulBaseService.notifyContentfulEmptyResults(contentfulQuery);
      return contentfulPagePathyContent;
    }

    contentfulPagePathyContent = {
      id: contentfulPagePathContentEntries.items[0].sys.id,
      locale: contentfulPagePathContentEntries.items[0].sys.locale as string, // FIXME: This cast is needed to avoid TypeScript error
      slug: contentfulPagePathContentEntries.items[0].fields.slug,
    };

    return contentfulPagePathyContent;
  }
}
