import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';

import { BuilderContent } from '@builder.io/sdk';
import { BuilderConfigService } from '@scalefast/config-angular';
import { MeCachisDependencyService } from '@scalefast/core';
import { GenericLogger, MECACHIS_DEPENDENCY_SERVICE } from '@scalefast/ecommerce-core';
import { Observable, firstValueFrom, map, of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuilderContentService {
  isServer: boolean;
  builderIOApiKey: string;

  constructor(
    private transferState: TransferState,
    private httpClient: HttpClient,
    private builderConfigService: BuilderConfigService,
    private logger: GenericLogger,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(MECACHIS_DEPENDENCY_SERVICE) private mecachisDependencyService: MeCachisDependencyService,
  ) {
    this.builderIOApiKey = this.builderConfigService.get().builderIOApiKey;
    this.isServer = isPlatformServer(this.platformId);
  }

  /**
   * Get the content from Builder.io by contentId.
   * If the content is already in the transfer state, it will return it from there.
   * @param contentId string
   * @returns Observable<BuilderContent[]>
   */
  getBuilderContent(contentId: string, allContent = false): Observable<BuilderContent[]> {
    return this.httpClient
      .get<{
        results: BuilderContent[];
      }>(`https://cdn.builder.io/api/v3/content/${contentId}?apiKey=${this.builderIOApiKey}&limit=100`)
      .pipe(
        map((builderContent) => {
          return allContent ? [...builderContent.results] : [builderContent.results[0]];
        }),
        tap((builderContent) => {
          if (builderContent.find((content) => !content.id || !content.lastUpdated)) {
            this.logger.warn({
              msg: `getBuilderContent builderContent: No lastUpdated or id found ${contentId}`,
              builderContent,
              allContent,
            });
            return;
          }

          this.logger.debug({
            msg: `getBuilderContent addBuilderDependency ${contentId}`,
            builderContent,
          });
          // This check is correct, because the content is already filtered by the find statement
          const contentToRevalidate = builderContent.map(({ id, lastUpdated }) => ({
            id: id!,
            lastUpdated: lastUpdated!,
          }));

          this.mecachisDependencyService.addBuilderDependency(contentId);
        }),
      );
  }

  getPageMetaBySlugHttp(contentId: string, options?: any): Observable<BuilderContent[]> {
    if (options) {
      options.apiKey = this.builderIOApiKey;
    } else {
      options = {
        apiKey: this.builderIOApiKey,
      };
    }
    const apiUrl = `https://cdn.builder.io/api/v3/content/${contentId}`;
    return this.httpClient.get(apiUrl, { params: options }).pipe(
      map((content: any) => {
        return content.results as BuilderContent[];
      }),
      tap((builderContent) => {
        if (!builderContent?.length) {
          this.logger.info({
            msg: `getPageMetaBySlugHttp: No content found ${contentId}`,
            options,
          });
          return;
        }
        let auxBuilderContent: BuilderContent[] = builderContent.map((content: any) => {
          if (content.data.page) {
            return content.data.page.value;
          } else {
            return content;
          }
        });
        if (auxBuilderContent.find((content) => !content.id || !content.lastUpdated)) {
          this.logger.warn({
            msg: `getPageMetaBySlugHttp builderContent: No lastUpdated or id found ${contentId}`,
            builderContent: JSON.stringify(auxBuilderContent),
            options,
          });
          return;
        }

        this.logger.debug({
          msg: `getPageMetaBySlugHttp addBuilderDependency ${contentId}`,
          builderContent: JSON.stringify(auxBuilderContent),
        });
        // This check is correct, because the content is already filtered by the find statement
        const contentToRevalidate = auxBuilderContent.map(({ id, lastUpdated }) => ({
          id: id!,
          lastUpdated: lastUpdated!,
        }));

        this.mecachisDependencyService.addBuilderDependency(contentId);
      }),
    );
  }

  // FIXME: same contenId can be used it for pages with different date
  getPageMetaBySlug(contentId: string, options?: any): Observable<BuilderContent[]> {
    const stateKey = makeStateKey<BuilderContent[]>(contentId);

    if (this.transferState.hasKey(stateKey)) {
      return of(this.transferState.get(stateKey, null) as BuilderContent[]);
    }

    return this.getPageMetaBySlugHttp(stateKey, options);
  }

  async prefetchMostPopularPages(): Promise<BuilderContent[]> {
    const params = {
      omit: 'meta.componentsUsed',
      'query.data.prefetch.$eq': true,
      enrich: true,
      fields: 'data.page,data.serverNavigation',
      apiKey: this.builderIOApiKey,
    };
    return firstValueFrom(this.httpClient.get(`https://cdn.builder.io/api/v3/content/page`, { params })).then(
      (builderContent: any) => {
        return builderContent.results as BuilderContent[];
      },
    );
  }
}
